import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step fileserver`}</strong>{` -- start an HTTP(S) server serving the contents of a path`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step fileserver <dir>
[--address=<address>] [--cert=<file>] [--key=<file>] [--roots=<file>]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step fileserver`}</strong>{` command starts an HTTP(S) server serving the contents of a file
system.`}</p>
    <p>{`This command is experimental and only intended for test purposes.`}</p>
    <h2>{`Positional arguments`}</h2>
    <p><inlineCode parentName="p">{`dir`}</inlineCode>{`
The directory used as root for the HTTP file server.`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--address`}</strong>{`=`}<inlineCode parentName="p">{`address`}</inlineCode>{`
The TCP `}<inlineCode parentName="p">{`address`}</inlineCode>{` to listen on (e.g. ":8443").`}</p>
    <p><strong parentName="p">{`--cert`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The `}<inlineCode parentName="p">{`file`}</inlineCode>{` containing the TLS certificate to use.`}</p>
    <p><strong parentName="p">{`--key`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The `}<inlineCode parentName="p">{`file`}</inlineCode>{` containing the key corresponding to the certificate.`}</p>
    <p><strong parentName="p">{`--roots`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The `}<inlineCode parentName="p">{`file`}</inlineCode>{` containing the root certificate(s) that will be used to verify the client certificates.`}</p>
    <h2>{`Examples`}</h2>
    <p>{`Start an HTTP file server on port 8080.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step fileserver --address :8080 /path/to/web-root
`}</code></pre>
    <p>{`Start an HTTPS file server on 127.0.0.1:8443.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca certificate 127.0.0.1 localhost.crt localhost.key
...
$ step fileserver --address 127.0.0.1:8443 \\
  --cert localhost.crt --key localhost.key /path/to/web-root
`}</code></pre>
    <p>{`Start an HTTPS file server on a random port and require client certificates.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step fileserver --cert localhost.crt --key localhost.key \\
  --roots $(step path)/certs/root_ca.crt /path/to/web-root
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      